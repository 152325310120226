import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AUTH_TOKEN } from "constants/AuthConstant";
import FirebaseService from "services/FirebaseService";
import axios from "axios";
import { API_DOMAIN } from "constants/ApiConstant";
import { v4 as uuidV4 } from "uuid";

export const initialState = {
  loading: false,
  message: "",
  showMessage: false,
  redirect: "",
  token: localStorage.getItem(AUTH_TOKEN) || null,
};

export const signIn = createAsyncThunk(
  "auth/signIn",
  async (data, { rejectWithValue }) => {
    //const { email, password } = data
    try {
      const uniqueId = uuidV4();
      const logindata = await axios
        .post(`${API_DOMAIN}/auth/login`, { ...data, uuid: uniqueId })
        .catch((err) => console.log(err, "err"));
      console.log(logindata, "logindata");
      // .then((item) => {
      //   console.log("item", item);
      // })
      // const response = await FirebaseService.signInEmailRequest(email, password)
      const response = logindata?.data;
      console.log(response, "response");
      if (response?.user) {
        console.log(response?.user);
        const token = response.access_token;
        //localStorage.setItem(AUTH_TOKEN, response.user.refreshToken);
        localStorage.setItem(AUTH_TOKEN, response.access_token);
        localStorage.setItem("email", response?.user?.email);
        localStorage.setItem("userDetail", JSON.stringify(response.user));
        axios.defaults.headers.common["Authorization"] = `bearer ${token}`;

        let res = await axios.post(`${API_DOMAIN}/users/getuserroledata`, {
          role: response.user.roles,
        });
        //.then((res) => {
        let values = {};
        let admin = {};
        res?.data?.userSettings?.admin?.forEach((setting) => {
          admin[setting?.path] = setting;
        });
        let is_admin = false;
        res?.data?.userSettings?.admin?.forEach((setting) => {
          if (!!setting?.read || !!setting?.edit || !!setting?.delete) {
            is_admin = true;
          }
        });
        let partners = {};
        res?.data?.userSettings?.partners?.forEach((setting) => {
          partners[setting?.path] = setting;
        });
        values = {
          ...res?.data?.userSettings,
          admin,
          partners,
          is_admin,
          name: response?.user?.name,
          partner_id: response?.user?.partner_id,
          image_url: response?.user?.image_url,
          email: response?.user?.email,
          id: response?.user?._id,
          isSalesRep: response?.user?.isSalesRep,
        };
        console.log(values, "valuesvalues61");
        localStorage.setItem("resdata", JSON.stringify(values));
        return {
          token,
          userdetail: values,
        };
        //});
        //return token;
        //localStorage.setItem("roleData",JSON.stringify(res.data.userSettings)

        //return token;
      } else {
        return rejectWithValue(response.message?.replace("Firebase: ", ""));
      }
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const signInData = createAsyncThunk(
  "auth/signInData",
  async (data, { rejectWithValue }) => {
    //const { email, password } = data
    try {
      let userSettings = JSON.parse(localStorage.getItem("resdata"));
      //console.log(userSettings,'resdataauthslice')
      //.then((res) => {
      //let values= {}
      //console.log(userSettings,'resdataauthslice103')
      // userSettings?.admin?.forEach((setting) => {
      //   admin[setting?.path] = setting;
      // });
      // let is_admin = false;
      // userSettings?.admin?.forEach((setting) => {
      //   if (!!setting?.read || !!setting?.edit || !!setting?.delete) {
      //     is_admin = true;
      //   }
      // });
      // let partners = {};
      // userSettings?.partners?.forEach((setting) => {
      //   partners[setting?.path] = setting;
      // });
      // values= {...userSettings,admin,partners,is_admin}
      // localStorage.setItem(
      //   "resdata",
      //   JSON.stringify(values)
      // );

      let token = localStorage.getItem("auth_token");
      //console.log(values,'values')
      return {
        token,
        userdetail: userSettings,
      };
      //});
      //return token;
      //localStorage.setItem("roleData",JSON.stringify(res.data.userSettings)

      //return token;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const signUp = createAsyncThunk(
  "auth/signUp",
  async (data, { rejectWithValue }) => {
    const { email, password } = data;
    try {
      const response = await FirebaseService.signUpEmailRequest(
        email,
        password
      );
      if (response.user) {
        const token = response.user.refreshToken;
        localStorage.setItem(AUTH_TOKEN, response.user.refreshToken);
        return token;
      } else {
        return rejectWithValue(response.message?.replace("Firebase: ", ""));
      }
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const signOut = createAsyncThunk("auth/signOut", async () => {
  const response = await FirebaseService.signOutRequest();
  localStorage.removeItem(AUTH_TOKEN);
  delete axios.defaults.headers.common["Authorization"];
  return response.data;
});

export const signInWithGoogle = createAsyncThunk(
  "auth/signInWithGoogle",
  async (_, { rejectWithValue }) => {
    const response = await FirebaseService.signInGoogleRequest();
    if (response.user) {
      const token = response.user.refreshToken;
      localStorage.setItem(AUTH_TOKEN, response.user.refreshToken);
      return token;
    } else {
      return rejectWithValue(response.message?.replace("Firebase: ", ""));
    }
  }
);

export const signInWithFacebook = createAsyncThunk(
  "auth/signInWithFacebook",
  async (_, { rejectWithValue }) => {
    const response = await FirebaseService.signInFacebookRequest();
    if (response.user) {
      const token = response.user.refreshToken;
      localStorage.setItem(AUTH_TOKEN, response.user.refreshToken);
      return token;
    } else {
      return rejectWithValue(response.message?.replace("Firebase: ", ""));
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authenticated: (state, action) => {
      state.loading = false;
      state.redirect = "/";
      state.token = action.payload;
    },
    showAuthMessage: (state, action) => {
      state.message = action.payload;
      state.showMessage = true;
      state.loading = false;
    },
    hideAuthMessage: (state) => {
      state.message = "";
      state.showMessage = false;
    },
    signOutSuccess: (state) => {
      state.loading = false;
      state.token = null;
      state.redirect = "/";
    },
    showLoading: (state) => {
      state.loading = true;
    },
    signInSuccess: (state, action) => {
      state.loading = false;
      state.token = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signIn.pending, (state) => {
        state.loading = true;
      })
      .addCase(signIn.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = "/";
        state.token = action.payload.token;
        state.userdetail = action.payload.userdetail;
      })
      .addCase(signIn.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(signInData.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = "/";
        state.token = action.payload.token;
        state.userdetail = action.payload.userdetail;
      })
      .addCase(signOut.fulfilled, (state) => {
        state.loading = false;
        state.token = null;
        state.redirect = "/";
      })
      .addCase(signOut.rejected, (state) => {
        state.loading = false;
        state.token = null;
        state.redirect = "/";
      })
      .addCase(signUp.pending, (state) => {
        state.loading = true;
      })
      .addCase(signUp.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = "/";
        state.token = action.payload;
      })
      .addCase(signUp.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(signInWithGoogle.pending, (state) => {
        state.loading = true;
      })
      .addCase(signInWithGoogle.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = "/";
        state.token = action.payload;
      })
      .addCase(signInWithGoogle.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(signInWithFacebook.pending, (state) => {
        state.loading = true;
      })
      .addCase(signInWithFacebook.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = "/";
        state.token = action.payload;
      })
      .addCase(signInWithFacebook.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      });
  },
});

export const {
  authenticated,
  showAuthMessage,
  hideAuthMessage,
  signOutSuccess,
  showLoading,
  signInSuccess,
} = authSlice.actions;

export default authSlice.reducer;
